<template>
  <div class="container">
    <el-descriptions title="用户信息" :column="2">
      <el-descriptions-item label="用户名">
         <link-to :to="{ name: 'userDetails', params: { id: user.user_id } }">  {{
        user.nick_name
      }}</link-to>
        
       </el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        user.mobile
      }}</el-descriptions-item>
      <el-descriptions-item label="教练">
          <link-to :to="{ name: 'userDetails', query: { id: coach.id } }">  {{
         coach.real_name
      }}</link-to>
       </el-descriptions-item>
      <el-descriptions-item label="教练手机号">{{
        coach.phone
      }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="申请信息">
      <el-descriptions-item label="课程">{{ need.title }}</el-descriptions-item>
      <el-descriptions-item label="总课节数"
        >{{ need.course_num }}节</el-descriptions-item
      >
      <el-descriptions-item label="申请补偿金额">{{
        record.amount
      }}</el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-tag v-if="record.status === 0" type="info">待处理</el-tag>
        <el-tag v-if="record.status === 1" type="success">已处理</el-tag>
        <el-tag v-if="record.status === 2" type="primary">已取消</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="类型">{{
        record.type == "user_apply" ? "用户申请" : "教练申请"
      }}</el-descriptions-item>
      <el-descriptions-item label="原因">{{
        record.remark
      }}</el-descriptions-item>
      <el-descriptions-item label="图片"
        ><div>
          <el-image
            v-for="(url, i) in record.pic.split(',')"
            :key="i"
            style="width: 150px; height: 150px"
            :src="url"
            :fit="cover"
            :preview-src-list="[record.pic.split(',')]"
          ></el-image></div
      ></el-descriptions-item>
    </el-descriptions>
    <div v-if="record.status == 0">
      <el-descriptions title="处理申请"> </el-descriptions>
      <el-form>
        <el-form-item label="处理结果">
          <el-radio v-model="handler.type" label="compensate">补偿申请人</el-radio>
          <el-radio v-model="handler.type" label="none">不补偿</el-radio>
        </el-form-item>

        <el-form-item label="处理说明">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="处理违规后给教练发送的违规提示消息"
            v-model="handler.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitHandler">处理申请</el-button>
          <el-button type="info" @click="cancelHandler">取消申请</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
 <el-form>
     <el-form-item label="处理人">
         {{record.handler_name}}
        </el-form-item>
         <el-form-item label="处理时间">
         {{record.update_time}}
        </el-form-item>
        <el-form-item label="处理结果">
            {{record.handler_result == 'none' ? '不补偿' : '补偿申请人'}}
        </el-form-item>

        <el-form-item label="处理说明">
         {{record.handler_context}}
        </el-form-item>
 </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      user: {},
      coach: {},
      need: {},
      record: {},
      handler: {},
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getInfo();
  },

  methods: {
    cancelHandler() {
      this.$confirm("确定取消用户提交的补偿申请？").then(() => {
        this.$axios({
          url: "/user/compensateRecord/update",
          data: {
            handler_id: this.adminInfo.id,
            handler_name: this.adminInfo.name,
             handler_result: this.handler.type,
            id: this.id,
            status: 2,
          },
          method: "post",
        }).then((res) => {
          this.$router.back();
        });
      });
    },
    submitHandler() {
      if (!this.handler.type || !this.handler.remark) {
        this.$message("请完善处理信息");
        return;
      }
      this.$axios({
        url: "/user/compensateRecord/update",
        data: {
          handler_id: this.adminInfo.id,
          handler_name: this.adminInfo.name,
          handler_result: this.handler.type,
          handler_context: this.handler.remark,
          id: this.id,
          status: 1,
        },
        method: "post",
      }).then((res) => {
        this.$message("成功")
        this.$router.back();
      });
    },
    getInfo() {
      this.$axios({
        url: "/user/compensateRecord/findById",
        params: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        this.record = res.data.data;
        this.user = this.record.map.userInfo;
        this.coach = this.record.map.coach;
        this.need = this.record.map.need;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>